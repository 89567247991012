<template>
  <div>
    <a-page-header title="评价管理" class="hearStyle" />
    <a-form layout="inline" class="formList">
      <a-form-item label="用户ID" label-width="70px">
        <a-input v-model.trim="searchForm.userId" placeholder="请输入用户ID" class="inputClass" />
      </a-form-item>
      <a-form-item label="商户名称">
        <a-input v-model.trim="searchForm.merchantName" placeholder="请输入商户名称" class="inputClass" />
      </a-form-item>
      <a-form-item label="所在项目">
        <a-select mode="tags" v-model="searchForm.projectIds" style="width: 200px" placeholder="全部"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option v-for="item in projectList" :key="item.id" :value="item.id">
            {{ item.project_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="口味评分">
        <a-select v-model="searchForm.tasteScore" style="width: 200px" placeholder="全部">
          <a-select-option v-for="item in openingList" :key="item.index" :value="item.index">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="服务体验">
        <a-select v-model="searchForm.serviceScore" style="width: 200px" placeholder="全部">
          <a-select-option v-for="item in openingList" :key="item.index" :value="item.index">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="推荐指数">
        <a-select v-model="searchForm.recommendScore" style="width: 200px" placeholder="全部">
          <a-select-option v-for="item in openingList" :key="item.index" :value="item.index">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="手机号">
        <a-input v-model.trim="searchForm.phoneNumber" placeholder="请输入手机号" class="inputClass" />
      </a-form-item>
      <a-form-item label="状态">
        <a-select v-model="searchForm.status" style="width: 200px" placeholder="全部">
          <a-select-option v-for="item in statusList" :key="item.index" :value="item.index">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="评价内容">
        <a-input v-model.trim="searchForm.assessText" placeholder="请输入评价内容" class="inputClass" />
      </a-form-item>
      <a-form-item label="评价时间">
        <a-range-picker show-time v-model="searchForm.activityTime" />
      </a-form-item>
      <a-form-item label="综合评价">
        <a-row style="width: 200px;">
          <a-col :span="10">
            <a-input v-model.trim="searchForm.minScore" />
          </a-col>
          <a-col :span="4">
            <div style="text-align: center;">至</div>
          </a-col>
          <a-col :span="10">
            <a-input v-model.trim="searchForm.maxScore" />
          </a-col>
        </a-row>
      </a-form-item>
      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px"><a-icon type="search" />查询</a-button>
        <a-button style="margin-left: 22.5px" @click="resetSearchForm"> 重置 </a-button>
        <a-tooltip placement="top" overlayClassName="tooltipColor">
          <template slot="title">
            <span>注：每次最大支持导出60000条</span>
          </template>
          <a-button style="margin-left: 22.5px;background-color: #e6a23c; color: #fff; border: #e6a23c;" @click="exportData">
            <a-icon type="plus" /> 导出
          </a-button>
        </a-tooltip>

      </span>
    </a-form>

    <a-table :columns="columns" style="margin-top: 10px" :data-source="tableData" :rowKey="(record, index) => {
      return index;
    }
      " :pagination="pagination.total ? pagination : false" @change="pageChange" :scroll="{ x: 1500 }" bordered>
      <span slot="linename" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{ record.linename }}
          </template>
          <span class="field">{{ record.linename }}</span>
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" :disabled="record.executeOperation === 0 || record.executeOperation === 2" @click="shieldfun(record.assessId)">屏蔽</a-button>
        <a-button type="link" :disabled="record.executeOperation === 0 || record.executeOperation === 1" @click="replyfun(record.assessId)">回复</a-button>
      </span>
    </a-table>
    <!-- 屏蔽弹框 -->
    <a-modal :visible="ifshield" @cancel="shieldfun" :destroyOnClose="true" :footer="null" width="20%">
      <ShieldList :currentShieldId="currentShieldId" @closeShield="closePopup" @reset="resetTableData"/>
    </a-modal>
    <!-- 回复弹框 -->
    <a-modal :visible="ifreply" @cancel="replyfun" :destroyOnClose="true" :footer="null" width="25%">
      <ReplyList :currentShieldId="currentShieldId" @closeReply="replyfun" @reset="resetTableData"/>
    </a-modal>
  </div>
</template>
<script>
import * as api from "@/api/popup";
import ShieldList from "./shield/index.vue"
import ReplyList from "./reply/index.vue"
import { evaluateList, exportData } from '@/api/mustEatList'
import {projectName} from "@/api/serviceBinding";
import moment from "moment";
import id from "element-ui/src/locale/lang/id";
import {requirementExport} from "@/api/demandPool";
import { mapState } from "vuex";
export default {
  components: { ShieldList, ReplyList },
  computed: {
    ...mapState({
      projectList: (state) => state.common.projectList
    }),
  },
  data() {
    return {
      ifshield: false,//屏蔽是否显示
      ifreply: false,//回复是否显示
      // 当前评论id
      currentShieldId: '',
      //口味搜索列表
      openingList: [
        {
          index: 1,
          label: 1
        },
        {
          index: 2,
          label: 2
        },
        {
          index: 3,
          label: 3
        },
        {
          index: 4,
          label: 4
        },
        {
          index: 5,
          label: 5
        }
      ],
      //状态搜索列表
      statusList: [
        {
          index: 1,
          label: "正常"
        },
        {
          index: 0,
          label: "已屏蔽"
        },
        {
          index: 2,
          label: "失效（退款）"
        },
      ],

      typeList: [
        //状态下拉值
        { label: "禁用", value: 1 },
        { label: "启用", value: 0 },
      ],
      searchForm: {
        // 用户id
        userId: '',
        // 商户名称
        merchantName: '',
        // 所在项目
        projectIds: undefined,
        // 口味评分
        tasteScore: undefined,
        // 服务评分
        serviceScore: undefined,
        // 推荐指数
        recommendScore: undefined,
        // 手机号
        phoneNumber: '',
        // 状态
        status: undefined,
        // 评价内容
        assessText: '',
        // 评价时间
        activityTime: undefined,
        // 综合分最高
        maxScore: '',
        // 综合分最低
        minScore: '',
      },
      //分页数据
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      columns: [
        { title: "用户ID", dataIndex: "userId", key: "userId", width: 100, align: "center" },
        { title: "手机号", dataIndex: "phoneNumber", key: "phoneNumber", width: 150, align: "center" },
        { title: "商户名称", dataIndex: "merchantName", key: "merchantName", width: 150, align: "center" },
        { title: "所在项目", dataIndex: "projectName", key: "projectName", width: 150, align: "center" },
        { title: "口味评分", dataIndex: "tasteScore", key: "tasteScore", width: 150, align: "center" },
        { title: "服务体验", dataIndex: "serviceScore", key: "serviceScore", width: 150, align: "center" },
        { title: "推荐指数", dataIndex: "recommendScore", key: "recommendScore", width: 150, align: "center" },
        { title: "评价内容", dataIndex: "assessText", key: "assessText", width: 150, align: "center" },
        {
          title: "评价时间",
          dataIndex: "createTime",
          key: "createTime",
          width: 150,
          align: "center",
          customRender(text) {
            return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '';
          },
        },
        { title: "综合评分", dataIndex: "finalScore", key: "finalScore", width: 150, align: "center" },
        { title: "订单流水号", dataIndex: "orderId", key: "orderId", width: 150, align: "center" },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: 150,
          align: "center",
          customRender(text) {
            return text === 0 ? "已屏蔽" : text === 1 ? "正常展示" : "失效（退款）";
          },

        },
        {
          title: "回复内容",
          dataIndex: "replyText",
          key: "replyText", width: 150,
          align: "center",
        },
        { title: "操作人", dataIndex: "updateBy", key: "updateBy", width: 150, align: "center" },
        {
          title: "操作时间",
          dataIndex: "updateTime",
          key: "updateTime",
          width: 150,
          align: "center",
          customRender(text) {
            return text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '';
          },
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 120,
          scopedSlots: { customRender: 'action' },
        },
      ],
      tableData: [],
    };
  },
  async created() {
    // 默认当前的数据
    const currentDate = moment(new Date()).format('YYYY-MM-DD')
    this.searchForm.activityTime = [currentDate, `${currentDate} 23:59:59`]
    await this.getTableList();
  },
  mounted() {
  },
  methods: {
    //屏蔽打开弹窗
    shieldfun(id) {
      this.currentShieldId = id
      this.ifshield = !this.ifshield
    },
    // 关闭屏蔽弹窗并初始化数据
    closePopup() {
      this.ifshield = false;
      // this.resetSearchForm()
    },
    // 关闭回复弹窗
    closeReplyPopup() {
      this.ifreply = false;
      this.resetSearchForm()
    },
    //回复打开弹框
    replyfun(id) {
      this.currentShieldId = id
      this.ifreply = !this.ifreply
    },
    //点击添加打开弹出
    async exportData() {
      try {
        let startTime = ''
        let endTime = ''
        if(this.searchForm.activityTime && this.searchForm.activityTime.length) {
          startTime = moment(this.searchForm.activityTime[0]).format('YYYY-MM-DD HH:mm:ss')
          endTime = moment(this.searchForm.activityTime[1]).format('YYYY-MM-DD HH:mm:ss')
        }
        const params = {
          startTime: startTime,
          endTime: endTime,
          ...this.searchForm
        }
        const res = await exportData(params);
        const blob = new Blob([res.data]);
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objectUrl;
        a.setAttribute('download', '评价数据.xlsx');
        a.click();
      } catch (e) {
        this.$message.error('您要导出的数据超过10000条，请缩小筛选范围后重试');
      }
    },
    //编辑添加打开弹出
    edmit(val) {
      // 判断弹窗类型是否是系统弹窗
      if (val.systemBounced === 0) {
        this.$router.push({
          path: "/popupAdmin/editPopup",
          query: { id: val.popupId, type: "0" },
        });
      } else {
        //非系统
        this.$router.push({
          path: "/popupAdmin/addPopup",
          query: { id: val.popupId, type: "1" },
        });
      }
    },
    //删除
    deleteData(record) {

    },
    //筛选
    search() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getTableList();
    },
    // 不清楚筛选条件
    resetTableData() {
      this.getTableList();
    },
    // 筛选重置
    resetSearchForm() {
      this.searchForm = this.$options.data.call(this).searchForm;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getTableList();
    },
    //确认禁用
    changeTypeDisabled(record) {

    },
    //确认启用
    changeTypeEnable(record) {
    },
    //获取列表数据
    async getTableList() {
      const params = this.getParams();
      console.log(params, '这是参数')
      const res = await evaluateList(params);
      const { code, data: { assessList, count } } = res;
      if (code === "200") {
        this.tableData = assessList;
        this.pagination.total = count;
      }
    },

    getParams(){
      let startTime = ''
      let endTime = ''
      if(this.searchForm.activityTime && this.searchForm.activityTime.length) {
        startTime = moment(this.searchForm.activityTime[0]).format('YYYY-MM-DD HH:mm:ss')
        endTime = moment(this.searchForm.activityTime[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      return {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        startTime: startTime,
        endTime: endTime,
        ...this.searchForm,
      };
    },
    //分页操作
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getTableList();
    },
  },

};
</script>
<style lang="scss" scoped>

.mt-15 {
  margin-top: 15px;
}

.hearStyle {
  padding-left: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(235, 237, 240);
}

::v-deep .ant-form-item {
  margin-right: 15px !important;
}

::v-deep .ant-form-item-label {
  width: 70px;
  text-align: justify;
  text-align-last: justify;
}

::v-deep .ant-calendar-picker {
  width: 400px !important;
}

.inputClass {
  width: 200px;
}

.ant-table-row .ant-btn {
  padding: 0;
  margin: 0 5px;
}

p {
  margin-bottom: 0px;
}
</style>
<style lang="scss">
.tooltipColor {
  .ant-tooltip-inner {
    // 这里是框框
    color: red;
    background-color: #fff !important;

  }

  .ant-tooltip-arrow::before {
    // 这里是小三角
    background-color: #fff !important;
  }
  //如果上面不起作用可使用这个试试
  .ant-tooltip-arrow-content {
    background-color: #fff !important;
  }
}
</style>
