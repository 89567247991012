<template>
    <a-form :layout="formLayout" style="text-align: center;">
      <a-form-item label="">
       <div>是否确认屏蔽该条用户评论内容的展示</div>
       <span>（该操作不可逆，请谨慎操作）</span>
      </a-form-item>
      <a-form-item>
        <div style="width: 100%; display: flex; justify-content: space-around;">
            <a-button type="primary" @click="onSubmitData"> 提交 </a-button>
        <a-button  @click="closePopup"> 关闭 </a-button>
        </div>
      </a-form-item>
    </a-form>
  </template>
  
  <script>
  import {shieldData} from "@/api/mustEatList";

  export default {
    props: ['currentShieldId'],
    data() {
      return {
        formLayout: 'horizontal',
      };
    },
    computed: {
      formItemLayout() {
        const { formLayout } = this;
        return formLayout === 'horizontal'
          ? {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
          }
          : {};
      },
      buttonItemLayout() {
        const { formLayout } = this;
        return formLayout === 'horizontal'
          ? {
            wrapperCol: { span: 14, offset: 4 },
          }
          : {};
      },
    },
    methods: {
      //点击关闭
      closePopup(){
        this.$emit("closeShield")
      },
      //点击提交
      async onSubmitData(){
          const params = {
            assessId: this.currentShieldId
          }
          const res = await shieldData(params);
          const { code, msg } = res;
          if(code === '200') {
            this.closePopup()
            this.$emit('reset')
            this.$message.success('操作成功')
          } else {
            this.$message.error(msg)
          }
      }
    },
  };
  </script>