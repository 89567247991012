import { fetchApi } from "@/utils/axios";
import http from "@/utils/axios"

const url = '/api/dscloud-app-forum'
/**
 * 评价列表
 */
export const evaluateList = (data) => {
    return fetchApi(url + '/web/Assess/getAssessList', data, 'POST', 'data', true)
}

/**
 * 获取榜单数据列表
 */
export const getRankingDataList = (data) => {
    return fetchApi(url + '/web/listData/list', data, 'get', 'params')
}

/**
 * 屏蔽评论
 */
export const shieldData = (data) => {
    return fetchApi(url + '/web/Assess/shield', data, 'post', 'data');
}

/**
 *
 */
export  const onSubmitData = (data) => {
    return fetchApi(url + '/web/Assess/reply', data, 'post', 'data')
}

/**
 * 评价导出
 */
export const exportData = (data) => {
    return http({
        url: url + '/web/Assess/exportAssessData',
        responseType: 'blob',
        timeout: 120000,
        method: 'post',
        data: data
    })
}