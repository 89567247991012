import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

// 获取服务绑定管理列表
export const serviceList = (size, page, data) => {
	const submitData = {
		'$paging.size': size,
		'$filter.name': 'null',
		'$paging.page': page,
		'$orderby': 'null',
		'$filter.params': data,
	};
	return fetchApi(URL.SERVICE_BINDING_INFO, submitData)
}

// 获取服务名称
export const serviceListName = () => {
	return fetchApi(URL.BINDING_SERVICER_LIST, null)
}

// 获取项目名称
export const projectName = () => {
	return fetchApi(URL.BINDING_PROJECT_LIST, null)
}

// 新增
export const addServiceBinding = (data) => {
	return fetchApi(URL.ADD_SERVICE_BINDING, data, 'post', 'params')
}

// 详情
export const bindingInfoById = (data) => {
	return fetchApi(URL.BINDING_INFO_BYID, data)
}

// 编辑
export const editServiceBinding = (data) => {
	return fetchApi(URL.EDIT_SERVICE_BINDING, data, 'post', 'params')
}

// 根据id查询对应服务
export const serviceListById = (data) => {
	return fetchApi(URL.SERVICE_LIST, data)
}
// 修改服务绑定是否常用
export const updateOften = (data) => {
	return fetchApi(URL.UPDATE_OFTEN, data, "post", "params")
}

// 新的服务绑定列表
export const newServiceList = (size, page, params) => {
	const data = {
		pageNum: page,
		pageSize: size,
		serviceId: params.serviceId,
		projectId: params.projectId,
		state: params.state
	}
	return fetchApi(URL.NEW_SERVICE_BINDING_LIST, data, "get", "params", true)
}

// 新的服务绑定详情
export const newBindingInfoById = (data) => {
	return fetchApi(URL.GET_BINDING_INFO_BY_ID,data, 'get', 'params')
}

// 新的服务绑定添加
export const newAddServiceBinding = (data) => {
	return fetchApi(URL.NEW_ADD_SERVICE_BINDING, data, 'PUT','data')
}
// 获取服务名称
export const newServiceListName = () => {
	return fetchApi(URL.NEW_BINDING_SERVICER_LIST, null)
}
// 模块list
export const findModuleList = (moduleId, projectId) => {
	return fetchApi(URL.FIND_MODULE_LIST + '/' + moduleId + '/' + projectId, null, 'get', 'params')
}
// 新的编辑绑定管理
export const newEditServiceBinding = (data) => {
	return fetchApi(URL.NEW_UPDATE_SERVICE_BINDING, data, 'POST','data')
}
