<template>
    <a-form-model :layout="formLayout" ref="ruleForm" :model="formList" :rules="rules" :labelCol="{ span: 10 }"
        :wrapperCol="{ span: 14 }">
        <a-form-model-item label="管理员昵称" prop="userId">
            <a-select placeholder="请选择" v-model="formList.userId">
                <a-select-option v-for="item in adminList" :key="item.value" :value="item.value">
                    {{ item.label }}
                </a-select-option>
            </a-select>
        </a-form-model-item>
        <a-form-model-item label="引用模板">
            <a-select placeholder="请选择" v-model="formList.templateid" @change="templatefun">
                <a-select-option v-for="item in templateList" :key="item.index" :value="item.index">
                    {{ item.label }}
                </a-select-option>
            </a-select>
        </a-form-model-item>
        <a-form-model-item label="回复内容" prop="assessText" class="replyClass">
            <a-textarea show-word-limit placeholder="请输入100字以内的回复" maxlength="100" v-model="formList.assessText" :rows="6"/>
            <span class="replyLengthClass">{{ formList.assessText.length }}/100</span>
        </a-form-model-item>
        <span
            style="color: red;font-size: 12px; margin-bottom: 20px; display: block;">注：请仔细核查回复内容，回复后不支持撤回，且每条用户评论仅支持回复一次</span>
        <div style="width: 100%; display: flex; justify-content:space-evenly;">
            <a-button type="primary" @click="onSubmit"> 提交 </a-button>
            <a-button @click="closedit"> 关闭 </a-button>
        </div>
    </a-form-model>
</template>
  
<script>
import {onSubmitData} from "@/api/mustEatList";

export default {
    props: ['currentShieldId'],
    data() {
        return {
            timer: null,
            formLayout: 'horizontal',
            //提交表单
            formList: {
                //管理员
                adminName: "",
              userId: undefined,
                //模板
                templateName: "",
                templateid: undefined,
                // 评价内容
                assessText: "",
            },
            //管理员昵称列表
            adminList: [
                {
                    value: 'shangyexiaoi',
                    label: "商业小i"
                },
                {
                    value: 'kefuxiaoi',
                    label: "客服小i"
                },
                {
                    value: 'xiaoizhushou',
                    label: "小i助手"
                },
                {
                    value: 'dianzhang',
                    label: "店长"
                },
            ],
            //模板列表
            templateList: [
                {
                    index: 1,
                    label: "模板1",
                    content: "亲爱的友友，感谢您的评价！您的满意是我们最大的动力，我们会继续努力提供更好的产品和服务。如果您有任何需求，请随时告诉我们，小i会尽力满足。再次感谢您的光顾，祝您生活愉快！"
                },
                {
                    index: 2,
                    label: "模板2",
                    content: "亲爱的友友，非常抱歉给您带来了不好的体验。我们非常重视您的反馈，会对您提出的问题进行认真的反思和改进。如果您愿意，诚挚邀请您再次光顾我们的店铺，我们会用更好的服务和质量来回馈您。"
                },
                {
                    index: 3,
                    label: "模板3",
                    content: "亲爱的友友，非常抱歉您在用餐时遇到了质量问题，我们对此表示深深的歉意。我们会立即对出现的问题进行调查和处理，并采取措施确保不再发生类似情况。"
                },
            ],
            rules: {
              userId: [
                    { required: true, message: '请选择管理员昵称', trigger: 'blur' }
                ],
                assessText: [
                    { required: true, message: '请填写回复内容', trigger: 'blur' }
                ],
            }
        };
    },
    computed: {
        formItemLayout() {
            const { formLayout } = this;
            return formLayout === 'horizontal' ? {
                labelCol: { span: 10 },
                wrapperCol: { span: 14 },
            } : {};
        },
    },
    methods: {
        //选中引用模板 更新回复内容
        templatefun(val) {
            this.templateList.map(item => {
                if (item.index === val) {
                    this.formList.assessText = item.content
                }
            })
        },
        //点击关闭
        closedit() {
            this.$emit("closeReply")
        },
        //点击提交
        onSubmit() {
            let that = this;
            this.$refs.ruleForm.validate(async valid => {
              if(valid) {
                const params = {
                  originId: this.currentShieldId,
                  replyId: this.currentShieldId,
                  ...this.formList
                }
                // 提交数据
                clearTimeout(this.timer)
                this.timer = setTimeout( () => {
                  that.handlerSubmit(params)
                }, 1000)
              }
            })
        },
      async handlerSubmit(params) {
        const res = await onSubmitData(params);
        const {code, msg} = res
        if (code === '200') {
          this.$message.success('操作成功')
          this.closedit()
          this.$emit('reset')
        } else {
          this.$message.error(msg)
        }
      }
    },
};
</script>
<style scoped lang="scss">
::v-deep .ant-form-item-label {
    width: 90px !important;
}

.replyClass {
    position: relative;
    .replyLengthClass {
        position: absolute;
        right: 20px;
        user-select: none;
        z-index: 999;
        top: -5px;
    }
}
</style>